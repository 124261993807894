@import '../../styles/media-queries';

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 32rem;
  position: relative;
  padding: 0 1.6rem;

  @include for-screen-up(440) {
    padding: 0 2.4rem;
  }

  @include for-screen-up(768) {
    padding: 0 3.2rem;
  }

  @include for-screen-up(1024) {
    padding: 0 2.4rem;
  }

  @include for-screen-up(1200) {
    padding: 0 4rem;
  }

  @include for-screen-up(1400) {
    padding: 0 6.4rem;
  }

  @include for-screen-up(1500) {
    padding: 0 7.2rem;
  }

  @include for-screen-up(1920) {
    padding: 0 2.4rem;
  }

  &__header {
    margin: auto;
    position: fixed;
    top: 1.6rem;
    flex: 0 1 auto;
    left: 1.6rem;
    right: 1.6rem;

    @include for-screen-up(440) {
      left: 2.4rem;
      right: 2.4rem;
    }

    @include for-screen-up(768) {
      left: 3.2rem;
      right: 3.2rem;
    }

    @include for-screen-up(1024) {
      left: 2.4rem;
      right: 2.4rem;
    }

    @include for-screen-up(1200) {
      left: 4rem;
      right: 4rem;
    }

    @include for-screen-up(1400) {
      left: 6.4rem;
      right: 6.4rem;
    }

    @include for-screen-up(1500) {
      left: 7.2rem;
      right: 7.2rem;
    }

    @include for-screen-up(1920) {
      left: 2.4rem;
      right: 2.4rem;
    }
  }

  &__content {
    min-height: 90vh;
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 1 auto;
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    @include for-screen-up(960) {
      z-index: 2;
    }
  }

  &__lazy-footer {
    &:not([class*='is-visible']) {
      min-height: 27.5rem;
    }
  }
}
