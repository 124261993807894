@import '../../styles/media-queries';

.footer-menu {
  $menu: &;

  &__heading {
    color: var(--text-color-primary-white);
    margin: 0 0 3.2rem;

    font-size: 2.2rem;
    font-weight: 400;
    line-height: 1.25;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__item {
    margin: 0;
    padding: 0;
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.25;
  }

  &__link {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__icon {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 2.1rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    & ~ #{$menu}__text {
      padding-left: 3.5rem;
    }
  }
}
