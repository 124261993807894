:root {
  --text-color-primary-white: #fff;
  --text-color-primary-black: #000;
  --text-color-primary: #181a1f;
  --text-color-secondary: #8e96a4;
  --text-color-grey: #666;
  --text-color-error: #f04c41;
  --text-color-primary-blue: #0176ff;
  --text-color-quaternary: #1e2f4b;

  --bg-color-primary-white: #fff;
  --bg-color-primary-blue: #1380ff;
  --bg-color-primary-blue-hover: #0176ff;
  --bg-color-primary-blue-active: #0176ff;
  --bg-color-primary-black: #000;
  --bg-color-primary-lightgrey: #f2f2f2;
  --bg-color-tertiary: #f2f2f2;
  --bg-color-primary-darkgrey: #35383f;
  --bg-color-secondary-grey: #8e96a4;
  --bg-color-quaternary: #f9fcff;

  --filter-blue-static: #f1f6ff;
  --filter-blue-static-2: #f5f7f9;
  --filter-blue-hover: #e8f0ff;
  --filter-blue-active: #ddeafe;
  --filter-light-blue-hover: #f2f9ff;
  --filter-light-blue-active: #edf6ff;
  --filter-error: #fff2f1;

  --color-primary-error: #ff938c;
  --color-primary-secondary: #8e96a4;
  --color-secondary-6: #f3f3f3;
  --color-secondary-7: #eaeaea;
  --color-secondary-4: #ddeafe;
  --color-secondary-5: #f8f9fb;
  --color-secondary-10: #00c35a;
  --color-secondary-24: #a5cbff;
  --color-secondary-30: #eaeaea;
  --color-secondary-31: #b2bac8;
  --color-secondary-42: #fa9600;
  --color-secondary-46: #ff2618;
  --color-secondary-48: #00d88e;
  --color-secondary-49: #f3f6fa;
  --color-secondary-71: #fff2f1;
  --color-secondary-80: #7875ff;
  --color-secondary-81: #8381ff;
  --color-secondary-82: #7856ff;
  --color-secondary-88: #ecf7ff;
  --color-secondary-92: #282c33;
  --color-secondary-93: #181a1f;
  --color-secondary-94: #b7f9d0;
  --color-secondary-95: #4285f4;
  --color-secondary-103: #f3f3f1;
  --color-secondary-104: #dbfee8;
  --color-secondary-105: #008133;

  --color-stroke-1: #f3f3f3;
  --color-stroke-2: #e3e5e9;
  --color-stroke-4: #1573ef;
}
