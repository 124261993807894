@import '../../styles/media-queries';

.menu {
  $menu: &;

  display: flex;
  gap: 1rem;
  justify-content: center;
  transition: all 0.2s ease;

  &__trigger {
    border-radius: 1.6rem;
    width: 4.8rem;
    height: 4.8rem;
    color: var(--text-color-primary-white);
    position: relative;
    z-index: 3;
  }

  &__tooltip {
    background: rgba(24, 26, 31, 0.2);
    display: none;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    #{$menu}_active & {
      display: flex;
    }
  }

  &__tooltip-body {
    background-color: var(--bg-color-primary-blue);
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 3.2rem 1.6rem 1.6rem;

    @include for-screen-up(601) {
      width: 50rem;
      padding: 4rem 1.6rem 1.6rem;
    }
  }

  &__logo {
    margin: 0 0 4rem 1.9rem;

    @include for-screen-up(601) {
      margin: 0 0 4rem 1.6rem;
    }

    path {
      fill: var(--bg-color-primary-white);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    @include for-screen-up(1400) {
      background-color: var(--bg-color-primary-blue);
      width: auto;
      border-radius: 10rem;
      padding: 0.4rem;
      flex-direction: row;
      gap: 0;
    }
  }

  &__item {
    display: flex;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1;

    color: var(--text-color-primary-white);

    @include for-screen-up(1400) {
      font-size: 1.4rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: none;
    padding: 1.2rem 1.6rem;

    @include for-screen-up(1400) {
      padding: 1.5rem 1.6rem;
    }
  }

  &__bg {
    border-radius: 9rem;
    background-color: var(--bg-color-primary-white);
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    pointer-events: none;

    transition: width 0.3s ease, left 0.3s ease;

    @include for-screen-up(960) {
      top: 0.345rem;
      bottom: 0.345rem;
    }

    @include for-screen-up(1366) {
      top: 0.4rem;
      bottom: 0.4rem;
    }
  }
}
