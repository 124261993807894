@import '../../styles/media-queries';

.logo {
  $logo: &;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color-primary-black);
  width: 15.2rem;
  height: 4.8rem;

  &__icon {
    display: block;
  }
}
