@import '../../styles/media-queries';

.header {
  $header: &;
  background: var(--bg-color-primary-white);
  display: flex;

  align-items: center;
  justify-content: space-between;

  border-radius: 2.4rem;
  box-sizing: border-box;
  gap: 1.6rem;
  z-index: 10;
  padding: 1.6rem;
  opacity: 0;
  transition: opacity 0.3s ease;

  &_animation-start {
    opacity: 1;
  }

  @include for-screen-up(600) {
    padding: 2.4rem;
  }

  @include for-screen-up(1400) {
    gap: 1rem;
    max-width: 175.2rem;
  }

  &__side {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    order: 1;
    box-sizing: border-box;
    gap: 1.6rem;
    position: relative;
    z-index: 1;

    @include for-screen-up(1400) {
      gap: 1rem;
    }

    &:last-child {
      flex: 1 1 auto;
      justify-content: flex-end;

      @include for-screen-up(1400) {
        flex: 0 1 auto;
        order: 3;
      }
    }
  }

  &__content {
    flex: 0 1 auto;
    order: 3;
    position: relative;

    @include for-screen-up(1400) {
      order: 2;
    }
  }
}
