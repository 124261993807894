@import '../../styles/media-queries';

.back-button {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9rem;
  width: 5.5rem;
  height: 4.5rem;
  transform: scale(1);
  transition: background-color 0.3s ease, transform 0.1s linear;

  @include for-screen-up(1280) {
    width: 6.5rem;
    height: 5.5rem;
  }

  @include for-screen-up(1680) {
    width: 6.6rem;
    height: 5.6rem;
  }
}
