@import '../../styles/media-queries';

.footer {
  $footer: &;
  overflow: hidden;
  position: relative;
  padding: 0 0 2.4rem;
  opacity: 0;
  transition: opacity 0.3s ease;

  &_animation-start {
    opacity: 1;
  }

  &__content {
    background: var(--bg-color-primary-blue);
    border-radius: 6.4rem;

    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    gap: 8rem;
    position: relative;
    z-index: 1;

    padding: 9.6rem 2.6rem;

    @include for-screen-up(440) {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include for-screen-up(768) {
      flex-wrap: nowrap;
      flex-direction: row;
      padding: 12rem 2.6rem;
      justify-content: space-around;
      gap: 0;
    }

    @include for-screen-up(1024) {
      justify-content: center;
      gap: 8rem;
    }

    @include for-screen-up(1400) {
      padding: 16rem 2.6rem;
    }
  }

  &__logo {
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: center;
  }

  &__col {
    width: 20rem;

    flex: 0 1 auto;

    @include for-screen-up(768) {
      padding-top: 2.15rem;
    }

    &:first-child {
      width: 100%;
      padding: 0;
      flex: 0 1 auto;

      @include for-screen-up(768) {
        width: auto;
        flex: 1 1 auto;
      }

      @include for-screen-up(1024) {
        flex: 0 1 auto;
        padding-right: 12rem;
      }
    }
  }
}
